:root
{
  --mainBlue:#2a2a72;
  --lightBlue:#009ffd;
  --mainWhite:#f3f3f3;
  --mainDark:#232528;
    --mainYellow:#ffa400;
    --back:#EDFFEC;
    --h1:#368B85;
  }

  .nav-logo{
    position: fixed;
    left: 0;
    top:10px;
    z-index: 999;
    
  }

  body
  {
    font-family: "Oswald", sans-serif!important;
    background: var(--back)!important;
    color: var(--mainDark)!important;
    
      }
  .text-title
  {
    font-family: "Permanent Marker", cursive;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
  }
  .text-blue
  {
    color:var(--mainBlue);
      }

      .text-bright
      {
        color:var(--lightBlue);
        
      }
      .btn-black
      {
background: transparent;
text-transform: capitalize;
font-size: 0.8rem !important;
color: var(--mainDark);
border-radius: 0 !important;
border: 0.1rem solid var(--mainDark) !important;
      }

.btn-black:hover{
  background: var(--mainDark);
  color: var(--mainWhite) !important;

}

.spalsh-img
{
  background-size:100% 100%;
  height:200px;
}
h1 {
  text-align: center;
  color: var(--h1);
}
p {
  font-family: 'Cookie';
  font-size: 26px;
  display: block;
  font-style: bold;
  font-variant: normal;
  font-weight: 500;
  line-height: 34px;
  text-align: justify;
  }
  div.b {
    line-height: 1.6;
  }
  
  #nt-example1 {
    max-width: 620px;
    margin: auto;
  }

  #nt-example1-container {
    text-align: center;
  }

  #nt-example1-container i {
    font-size: 12px;
    margin: 8px;
    cursor: pointer;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  #nt-example1-container i:hover {
    color: #333;
  }

  #nt-example1 li {
    color: #4e4e4e;
    background: var(--back);
    overflow: hidden;
    height: 100px;
    padding: 10px;
    line-height: 30px;
    list-style: none;
    font-size: 16px;
    text-align: left;
    border-bottom: 1px dotted #2c8162;
  }

  #nt-example1 li:hover {
    background: #fff;
  }
  .img-circle {
    
    width: 40px;
    height: 40px;
    z-index:3;
    background-color: darkgreen;

    
    }
    .color-grey
    {
      color: darkgrey;
    }
    .img-circle2 {
    
      width: 40px;
      height: 40px;
      z-index:3;
      background-color:white;
      
      }
    .img-circle1 {
    position: relative;
    border-radius:50%;
    width: 100px;
    height: 100px;
     z-index: -1;
     
    }
    #img-circle1 {
      position: relative;
      border-radius:50%;
      width: 100px;
      height: 100px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
     
      }
      .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
  
      .navbar {
        overflow: hidden;
        background-color:darkgreen;
        text-align: center;
        width: 100%;
      }
      
   
 
  
.social-footer {
  right:0;
      left: 0;
      bottom: 0;
      width: 100%;
      float: left;
      /* Height of the footer*/ 
      padding: 1rem;
      height: 160px;
      background:darkgreen;
      color:white;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      text-align:center;
      margin-top: 80px;
  }



  
 

.href1
{
  color:white;
}
.Container-color
{
background-color:darkgreen;
width:100%;
padding: 30px;
right: 0;
left: 0;

}
.Container-text
{
  color: white;
  text-align: center;
  font-size: small;
}
h6{
  color: white;
}